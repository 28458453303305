<template>
  <b-card v-if="smtp">
    <b-row>
      <b-col cols="4">
        <b-form-group label="SMTP服务器地址">
          <b-form-input v-model="smtp.host" placeholder="SMTP ip" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="端口">
          <b-form-input v-model="smtp.port" placeholder="SMTP port" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="答复地址">
          <b-form-input v-model="smtp.reply" placeholder="reply to" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="4">
        <b-form-group label="登陆账号">
          <b-form-input v-model="smtp.login" placeholder="login" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="登陆密码">
          <b-input-group>
            <b-form-input
              v-model="smtp.pwd"
              placeholder="pwd"
              :type="showPwd ? 'text' : 'password'"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="showPwd ? 'EyeIcon' : 'EyeOffIcon'"
                @click="showPwd = !showPwd"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="primary" @click="save"> 保存修改 </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  componentsPlugin,
} from "bootstrap-vue";

export default {
  name: "SMTPSetting",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      smtp: null,
      smtpOrg: null,

      showPwd: false,
    };
  },

  beforeCreate() {
    this.$http.get("/configdatas", { eid: 1 }).then((res) => {
      // debugger;
      var list = res.data.data.list;

      var config = list.find((item) => item.name.startsWith("smtp"));
      if (config) {
        this.smtpOrg = config;
        this.smtp = JSON.parse(config.value);
      }
    });
  },

  methods: {
    save() {
      var jsonValue = JSON.stringify(this.smtp);
      this.$http
        .put("/configdatas/" + this.smtpOrg.id, { value: jsonValue })
        .then((res) => {
          this.$swal({
            title: "保存成功",
            text: "成功",
            icon: "success",
          });
        });
    },
  },
};
</script>